// Used to return a formatted and consistent string from a human readable cms field.
// If the field is not required and left empty, 'default' is returned
export const consistentString = (string) => {
  if (string !== null && typeof string !== 'undefined') {
    return string.replace(/\s+/g, '-').replace(/'+/g, '').toLowerCase();
  }

  return 'default';
};

export const capitaliseString = (string) =>
  `${string[0].toUpperCase()}${string.substring(1)}`;

// Converts mime types to consistent filetype strings
// If a filetypes is missing here, simply add it to the switch statement
// See here for a list of possible mime types https://www.freeformatter.com/mime-types-list.html
export const mimeTypeToString = (mime) => {
  switch (mime) {
    case 'image/jpeg':
      return 'JPEG';
    case 'image/png':
      return 'PNG';
    case 'application/vnd.oasis.opendocument.text':
      return 'ODT';
    default:
      return null;
  }
};

// Checks if param is a string and contains characters other than spaces
export const stringIsNotEmpty = (str) =>
  typeof str === 'string' && str.trim().length !== 0;
